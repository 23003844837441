import { ClickAwayListener } from "@praticabr/ppsa";
import { Menu } from "lucide-react";
import React from "react";
import { MenuSelector } from "./MenuSelector";
import "./styles.scss";

type Props = {
	handleEdit: () => void;
	handleDelete: () => void;
	handleObservation: () => void;
	handleToMeet: () => void;
	isDealer: boolean;
	order: any;
};

export const ActionMenu = ({
	handleDelete,
	handleEdit,
	handleObservation,
	handleToMeet,
	isDealer,
	order
}: Props) => {
	const [showMenuSelector, setShowMenuSelector] = React.useState(false);

	const handlerUserSelectorToggle = () => {
		setShowMenuSelector(!showMenuSelector);
	};

	return (
		<>
			<ClickAwayListener onClickAway={() => setShowMenuSelector(false)}>
				<div className="action-menu" onClick={handlerUserSelectorToggle}>
					<Menu color={"#025cb7"} width={"30px"} height={"30px"} />
					{showMenuSelector && (
						<MenuSelector
							{...{
								handleDelete,
								handleEdit,
								handleObservation,
								handleToMeet,
								isDealer,
								order
							}}
						/>
					)}
				</div>
			</ClickAwayListener>
		</>
	);
};
