import {
	Button,
	ConfirmModal,
	Divider,
	GenericTable,
	LoadingProgress
} from "@praticabr/ppsa";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ActionMenu } from "../../../components/ActionMenu";
import { EventList } from "../../../components/EventList";
import { InfoTimeLine } from "../../../components/InfoTimeline";
import { InfoTimelineMobile } from "../../../components/InfoTimeline/InfoTimelineMobile";
import { ModalCancellation } from "../../../components/ModalCancellation";
import { ModalObservation } from "../../../components/ModalObservation";
import { ToMeetModal } from "../../../components/ToMeetModal";
import { OrderContext } from "../../../context/OrderContext";
import { useViewVariable } from "../../../context/ViewContext";
import { MessageBalloon } from "./MessageBalon";
import "./styles.scss";

export interface RequestStatusUpdateForm {
	content: string;
	requestId: number;
	type: string;
}

type OrderViewProps = {
	handleEdit: () => void;
	order: any;
};

export const OrderView = ({ order, handleEdit }: OrderViewProps) => {
	const [line, setLine] = useState<string>("items");
	const [deleteOrder, setDeleteOrder] = useState(false);
	const [toMeetModalShow, setToMeetModalShow] = useState(false);
	const [cancellationModalShow, setCancellationModalShow] = useState(false);
	const [observationModalShow, setObservationModalShow] = useState(false);
	const [showMessage, setShowMessage] = useState<boolean>(true);
	const { mobileSize } = useViewVariable();
	const { partner } = useSelector((state: any) => state?.auth);

	const {
		handleOrderDelete,
		handleRequestUpdateStatus,
		HandleCancelRequestById,
		isLoading
	} = useContext(OrderContext);

	const isDealer = () => {
		return order?.partnerId === partner.id;
	};

	const handleDelete = () => {
		order?.status !== "NEW" && setCancellationModalShow(true);
		order?.status === "NEW" && setDeleteOrder(true);
	};

	const handleToMeet = () => {
		setToMeetModalShow(true);
	};

	const handleObservation = () => {
		setObservationModalShow(true);
	};

	const onSubmitCancellation = (data: RequestStatusUpdateForm) => {
		handleRequestUpdateStatus(data, (error: Error) => {
			if (!error) {
				setCancellationModalShow(false);
			}
		});
	};

	const onSubmitObservation = (data: RequestStatusUpdateForm) => {
		handleRequestUpdateStatus(data, (error: Error) => {
			if (!error) {
				setObservationModalShow(false);
			}
		});
	};

	const menuAction = () => (
		<ActionMenu
			{...{
				handleEdit,
				handleDelete,
				handleToMeet,
				handleObservation
			}}
			isDealer={isDealer()}
			order={order}
		/>
	);

	const allAttended = () => {
		if (order?.items?.length > 0) {
			return order?.items.every(
				(item: any) => item?.amountServed >= item?.amount
			);
		}
		return false;
	};

	const noneAttended = () => {
		if (order?.items?.length > 0) {
			return order?.items.every((item: any) => item?.amountServed === 0);
		}
		return false;
	};

	return (
		<div className="order-view">
			{order && (
				<div className="timeline-container">
					{!mobileSize ? (
						<InfoTimeLine order={order} />
					) : (
						<InfoTimelineMobile order={order} />
					)}
					<div className="timeline">
						{!mobileSize ? (
							!isDealer() ? (
								<>
									{order?.status === "CANCELLATION_REQUEST" && (
										<div className="header-info cancellation">
											<span>
												Esta solicitação está em processo de cancelamento.
											</span>
										</div>
									)}
								</>
							) : (
								<div className="header-info">
									<span>
										O valor das peças reflete unicamente o valor de tabela da
										Prática. Não estão inclusos impostos, frete e acréscimos por
										custos operacionais da concessionária.
									</span>
								</div>
							)
						) : (
							showMessage && (
								<MessageBalloon
									status={order?.status}
									onClose={() => setShowMessage(false)}
								/>
							)
						)}
						<div className="menu">
							<div className="line">
								<div className="items-list" onClick={() => setLine("items")}>
									<span className={`${line === "items" && "active"}`}>
										ITENS DA SOLICITAÇÃO
									</span>
								</div>
								<div className="timeline" onClick={() => setLine("timeline")}>
									<span className={`${line === "timeline" && "active"}`}>
										TIMELINE
									</span>
								</div>
							</div>
							{order?.status === "CANCELED" ? (
								<></>
							) : !isDealer() ? (
								order?.status === "CANCELLATION_REQUEST" ? (
									<Button
										title="Aprovar cancelamento"
										size="md"
										variant="confirmation-solid"
										style={{ width: "160px" }}
										onClick={() => HandleCancelRequestById(order?.id)}
									/>
								) : (
									menuAction()
								)
							) : (
								menuAction()
							)}
						</div>
						{line === "items" ? (
							<div className="content-items">
								{!allAttended() && (
									<div className="order-list-items">
										<Divider title="ITENS DA PENDENTES" />
										<GenericTable
											data={[
												...(order.items
													.filter(
														(item: any) => item?.amount - item?.amountServed > 0
													)
													.map((item: any) => ({
														...item,
														product: item?.code + " - " + item?.product?.b1Desc,
														amount:
															item?.amount - item?.amountServed < 0
																? 0
																: item?.amount - item?.amountServed,
														price: item?.price.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL"
														}),
														totalPrice:
															(item?.amount * item?.price)?.toLocaleString(
																"pt-BR",
																{
																	style: "currency",
																	currency: "BRL"
																}
															) || 0,
														ipi: item?.product?.b1Ipi
													})) || [])
											]}
											columns={[
												{
													key: "product",
													header: "Produto",
													width: "40%",
													flex: true
												},
												{
													key: "price",
													header: "Valor unitário (R$)",
													width: "20%",
													flex: true
												},
												{
													key: "totalPrice",
													header: "Total s/impostos (R$)",
													width: "20%",
													flex: true
												},
												{
													key: "amount",
													header: "Qtd",
													width: "10%"
												},
												{
													key: "ipi",
													header: "IPI (%)",
													width: "10%"
												}
											]}
										/>
									</div>
								)}
								{!noneAttended() && (
									<div className="order-list-items">
										<Divider title="ITENS ATENDIDO" />
										<GenericTable
											data={[
												...(order.items
													.filter((item: any) => item?.amountServed)
													.map((item: any) => ({
														...item,
														product: `${item?.code} - ${item?.product?.b1Desc}`,
														amountServed: item?.amountServed,
														price: item?.price?.toLocaleString("pt-BR", {
															style: "currency",
															currency: "BRL"
														}),
														totalPrice:
															(
																item?.amountServed * item?.price
															)?.toLocaleString("pt-BR", {
																style: "currency",
																currency: "BRL"
															}) || 0,
														ipi: item?.product?.b1Ipi
													})) || [])
											]}
											columns={[
												{
													key: "product",
													header: "Produto",
													width: "55%",
													flex: true
												},
												{
													key: "price",
													header: "Valor unitário (R$)",
													width: "10%",
													flex: true
												},
												{
													key: "totalPrice",
													header: "Total s/impostos (R$)",
													width: "15%",
													flex: true
												},
												{
													key: "amountServed",
													header: "Qtd",
													width: "10%"
												},
												{
													key: "ipi",
													header: "IPI (%)",
													width: "10%"
												}
											]}
										/>
									</div>
								)}
							</div>
						) : (
							<div className="content-timeline">
								<EventList eventList={order?.events} />
							</div>
						)}
					</div>
				</div>
			)}
			{deleteOrder && (
				<ConfirmModal
					title="EXCLUIR SOLICITAÇÃO"
					onConfirm={() => handleOrderDelete(order?.id)}
					onCancel={() => {
						setDeleteOrder(false);
					}}
					confirmLabel="Sim"
					cancelLabel="Não"
					text={
						<span>
							Tem certeza que deja excluir a solicitação{" "}
							<strong>{order?.id}</strong>?
						</span>
					}
					subText="Essa operação não poderá ser desfeita."
				/>
			)}
			{toMeetModalShow && (
				<ToMeetModal
					items={order?.items}
					onClose={() => setToMeetModalShow(false)}
					requestId={order?.id}
				/>
			)}
			{cancellationModalShow && (
				<ModalCancellation
					onClose={() => setCancellationModalShow(false)}
					requestId={order?.id}
					{...{ onSubmitCancellation }}
				/>
			)}
			{observationModalShow && (
				<ModalObservation
					onClose={() => setObservationModalShow(false)}
					requestId={order?.id}
					{...{ onSubmitObservation }}
				/>
			)}
			{(!order || isLoading) && <LoadingProgress />}
		</div>
	);
};
