import React from "react";
import { useRoutes } from "react-router-dom";
import {
	DefaultLayout,
	ProtectedRoute,
	UnprotectedRoutes
} from "../components";
import { Notification } from "../components/Notification";
import { PartnerDropdown } from "../components/PartnerDropdown";
import { UserDropdown } from "../components/UserDropdown";
import { UserProvider } from "../context";
import { DealerProvider } from "../context/DealerContext";
import { NotificationProvider } from "../context/NotificationContext";
import { OrderProvider } from "../context/OrderContext";
import { PartnerProvider } from "../context/PartnerContext";
import { ProductsProvider } from "../context/ProductsContext";
import { Sb1010sProvider } from "../context/Sb1010sContext";
import { SellerProvider } from "../context/SellerContext";
import { TechnicalProvider } from "../context/TechnicalContext";
import { AuthenticatePage } from "../pages";
import { CatalogPage } from "../pages/Catalog";
import { CatalogEditorPage } from "../pages/CatalogEditor";
import { ManagementSubTag } from "../pages/CatalogEditor/ManagementSubTag";
import { ManagementTag } from "../pages/CatalogEditor/ManagementTag";
import { OrderDetails } from "../pages/Order";
import { OrderCreate } from "../pages/Order/OrderCreate";
import { OrdersList } from "../pages/OrdersList";
import { PageError } from "../pages/PageError";
import { Partners } from "../pages/Partners";
import { PraticaBillets } from "../pages/PraticaBillets";
import { PraticaOrderPage } from "../pages/PraticaOrder";
import { PraticaOrders } from "../pages/PraticaOrders";
import { PraticaPartsToReturn } from "../pages/PraticaPartsToReturn";
import { Users } from "../pages/Users";

export default () =>
	useRoutes([
		{
			path: "/:language/",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<ProductsProvider>
									<CatalogPage />
								</ProductsProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/login",
			element: (
				<UnprotectedRoutes>
					<AuthenticatePage />
				</UnprotectedRoutes>
			)
		},
		{
			path: "/:language/recover-password",
			element: (
				<UnprotectedRoutes>
					<AuthenticatePage />
				</UnprotectedRoutes>
			)
		},
		{
			path: "/:language/requests",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<OrderProvider>
									<OrdersList />
								</OrderProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/newRequest",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<SellerProvider>
									<Sb1010sProvider>
										<OrderCreate />
									</Sb1010sProvider>
								</SellerProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/request/:id",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<OrderProvider>
									<DealerProvider>
										<Sb1010sProvider>
											<OrderDetails />
										</Sb1010sProvider>
									</DealerProvider>
								</OrderProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/pratica/orders",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<PraticaOrders />
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/pratica/order/:c5Filial/:c5Num",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle backButton />
							<DefaultLayout.sectionArticle>
								<PraticaOrderPage />
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/pratica/billets",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<PraticaBillets />
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/pratica/partsToReturn",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<PraticaPartsToReturn />
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/editor/catalog",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<Sb1010sProvider>
									<CatalogEditorPage />
								</Sb1010sProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/editor/tags",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<Sb1010sProvider>
									<ManagementTag />
								</Sb1010sProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/editor/subTags",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<Sb1010sProvider>
									<ManagementSubTag />
								</Sb1010sProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/users",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<TechnicalProvider>
									<UserProvider>
										<Users />
									</UserProvider>
								</TechnicalProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/partners",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle />
							<DefaultLayout.sectionArticle>
								<TechnicalProvider>
									<PartnerProvider>
										<DealerProvider>
											<Partners />
										</DealerProvider>
									</PartnerProvider>
								</TechnicalProvider>
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		},
		{
			path: "/:language/error",
			element: (
				<ProtectedRoute>
					<NotificationProvider>
						<DefaultLayout.headerActions>
							<PartnerDropdown />
							<Notification />
							<UserDropdown />
						</DefaultLayout.headerActions>
						<DefaultLayout.root>
							<DefaultLayout.headerArticle backButton />
							<DefaultLayout.sectionArticle>
								<PageError />
							</DefaultLayout.sectionArticle>
						</DefaultLayout.root>
					</NotificationProvider>
				</ProtectedRoute>
			)
		}
	]);
