import { Textarea } from "@praticabr/ppsa";
import React, { useContext } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { PraticaContext } from "../../../../context/PraticaContext";
import {
	BRANCH_TYPES,
	CATEGORY_TYPES,
	dateProtheusToDate,
	FREIGHT_TYPES,
	handleType
} from "../../../../utils/protheus-functions";
import "./styles.scss";

export const InfoTimelineMobile = () => {
	const { sc5010 } = useContext(PraticaContext);

	return (
		<div className="timeline-info-mobile">
			<div className="timeline-info-mobile-header">
				<IoIosArrowBack
					color="#004687"
					size={30}
					onClick={() => history.back()}
				/>
				<header>Pedido: {sc5010?.c5Num}</header>
			</div>
			<div className="timeline-info-mobile-infos">
				<div className="timeline-info-mobile-info">
					<header>Local: </header>
					{handleType({ value: sc5010?.c5Filial, types: BRANCH_TYPES })}
				</div>
				<div className="timeline-info-mobile-info">
					<header>Data: </header>
					{dateProtheusToDate(sc5010?.c5Emissao)?.toLocaleDateString("pt-BR")}
				</div>
				<div className="timeline-info-mobile-info">
					<header>Cliente:</header>
					<span>{sc5010?.c5Client}</span>
				</div>
				<div className="timeline-info-mobile-info">
					<header>Loja:</header>
					<span>{sc5010?.c5Lojacli}</span>
				</div>
				<div className="timeline-info-mobile-info">
					<header>Categoria:</header>
					<span>
						{handleType({
							value: sc5010?.c5Categor,
							types: CATEGORY_TYPES
						})}
					</span>
				</div>
				<div className="timeline-info-mobile-info">
					<header>Tipo de frete:</header>
					<span>
						{handleType({
							value: sc5010?.c5Tpfrete,
							types: FREIGHT_TYPES
						})}
					</span>
				</div>
				<div className="timeline-info-mobile-info">
					<header>OS Protheus:</header>
					<span>{sc5010?.c5Ordserv}</span>
				</div>
				<div className="timeline-info-mobile-info">
					<header>OS PRP:</header>
					<span>{sc5010?.c5Xprp}</span>
				</div>
			</div>
			<div className="timeline-info-mobile-observation">
				<Textarea>
					<Textarea.Label title="Observação para nota" htmlFor="observation" />
					<Textarea.Field
						id="observation"
						disabled
						value={sc5010?.c5Mennota}
						rows={4}
					/>
				</Textarea>
			</div>
		</div>
	);
};
