import {
	Button,
	Divider,
	Input,
	LoadingProgress,
	Select
} from "@praticabr/ppsa";
import { t } from "i18next";
import { ChevronLeft, LockIcon } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AuthContext } from "../../context";
import { updateAccountRequest } from "../../models/usecases/users";
import { updatePartnerId } from "../../services";
import { updatePartner } from "../../store/modules/auth/actions";
import { UpdatePasswordMenu } from "../UpdatePasswordMenu";
import "./style.scss";

interface ProfileMenuProps {
	show: boolean;
	onClose: () => void;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ show, onClose }) => {
	const [showMenuPassword, setShowMenuPassword] = useState<boolean>(false);
	const [editOn, setEditOn] = useState<boolean>(false);
	const [loading, setIsLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const {
		availablePartners = [],
		partner,
		id
	} = useSelector((state: any) => state?.auth);
	const { isLoading, user, onUpdateUser } = useContext(AuthContext);
	const optionsAvailablePartners = availablePartners.map((item: any) => {
		return {
			value: item?.id,
			label: item?.name
		};
	});

	useEffect(() => {}, [onUpdateUser]);

	const {
		handleSubmit,
		register,
		formState: { errors },
		control
	} = useForm<updateAccountRequest>({
		defaultValues: {
			partner: user?.partner,
			token: user?.token
		}
	});

	const onSubmit = (data: any) => {
		const { contact, email, token } = data;

		const updatedData = {
			email,
			contact,
			token
		};

		onUpdateUser(updatedData, onClose);
	};

	const handleChangePartner = async (partnerId: number) => {
		setIsLoading(true);
		await updatePartnerId({ userId: id, partnerId })
			.then((response) => {
				toast.success("Parceiro atualizar com sucesso!");
				dispatch(updatePartner(response?.data?.partner));
				window.location.reload();
			})
			.catch(() => {
				toast.error("Erro ao atualizar o parceiro");
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div className={`profile-menu ${show ? "show" : ""}`}>
			<div className="profile-menu-item" onClick={onClose}>
				<ChevronLeft size={32} />
			</div>
			<div className="profile-menu-data">
				<Divider title="Dados pessoais" />
				<div className="profile-menu-line">
					<label>{t("userConfigModal.emailInput.label")}</label>
					<Input
						variant="light"
						defaultValue={user?.email}
						errors={errors?.email}
						placeholder={t("userConfigModal.emailInput.placeholder")}
						disabled={!editOn}
						{...register("email", {
							required: {
								value: true,
								message: t("userConfigModal.emailInput.requiredAlert")
							},
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: t("userConfigModal.emailInput.invalidAlert")
							}
						})}
					/>
				</div>
				<div className="profile-menu-line">
					<label>{t("userConfigModal.contactInput.label")}</label>
					<Input
						variant="light"
						defaultValue={user?.contact}
						errors={errors?.contact}
						placeholder={t("userConfigModal.contactInput.placeholder")}
						disabled={!editOn}
						{...register("contact", {
							required: {
								value: true,
								message: t("userConfigModal.contactInput.requiredAlert")
							}
						})}
					/>
				</div>
				<div className="profile-menu-line">
					<label>{t("userConfigModal.partnerCodeInput")}</label>
					<Input
						name="partnerCode"
						variant="light"
						disabled
						value={
							user?.partner.code?.slice(0, 6) +
							"-" +
							user?.partner.code?.slice(6, 8)
						}
					/>
				</div>
				<div
					className="profile-menu-line-password"
					onClick={() => setShowMenuPassword(true)}
				>
					<LockIcon />
					<span>{t("userConfigModal.changePasswordAction")}</span>
				</div>
				<Divider title="Selecionar parceiro" />
				<div className="profile-menu-line">
					<Select
						onSelectOptions={(item) =>
							partner?.id !== Number(item[0]) &&
							handleChangePartner(Number(item[0]))
						}
						options={optionsAvailablePartners}
						selectedOption={[partner?.id]}
					/>
				</div>
			</div>
			<div className="profile-menu-action">
				{!editOn ? (
					<Button
						size="lg"
						title={t("userConfigModal.editAction")}
						variant="confirmation-solid"
						onClick={() => setEditOn(true)}
					/>
				) : (
					<>
						<Button
							size="lg"
							title={t("userConfigModal.submitAction")}
							variant="confirmation-solid"
							onClick={() => handleSubmit(onSubmit)()}
						/>
						<Button
							size="lg"
							title={t("userConfigModal.cancelAction")}
							variant="cancellation"
							onClick={() => setEditOn(false)}
						/>
					</>
				)}
			</div>
			{(isLoading || loading) && <LoadingProgress />}
			<UpdatePasswordMenu
				show={showMenuPassword}
				onClose={() => setShowMenuPassword(false)}
			/>
		</div>
	);
};
