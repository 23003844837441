import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import NotificationBellSvg from "../../assets/SvgIcons/NotificationBellSvg";
import NotificationSvg from "../../assets/SvgIcons/NotificationSvg";
import { SocketContextElement } from "../../context/SocketContext";
import { useViewVariable } from "../../context/ViewContext";
import { store } from "../../store";
import user from "../../store/modules/user/reducer";
import NotificationsModal from "../NotificationsModal";
import "./styles.scss";

type NotificationProps = {
	colorBell?: "white" | "025CB7";
};

export const Notification = ({ colorBell }: NotificationProps) => {
	const { eventListener } = useContext(SocketContextElement);
	const { mobileSize } = useViewVariable();
	const [notified, setNotified] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const { email, id } = store.getState().auth;

	useEffect(() => {
		socketEvents();
	}, [user, email]);

	useEffect(() => {}, []);

	useEffect(() => {
		if (notified) {
			const notificationBellIcon = document.querySelector(
				".notification-bell-svg"
			) as HTMLElement;
			if (notificationBellIcon)
				notificationBellIcon.classList.add("shake-icon");
		} else {
			const notificationBellIcon = document.querySelector(
				".notification-bell-svg"
			) as HTMLElement;
			if (notificationBellIcon)
				notificationBellIcon.classList.remove("shake-icon");
		}
	}, [notified]);

	const socketEvents = () => {
		if (email) {
			const socketTopic = `notify_ptp_report/${email}`;
			eventListener(socketTopic, (err: boolean, data: any) => {
				if (!err) {
					setNotified(true);
				} else {
					toast.error("Houve um erro ao buscar as últimas notificações!");
				}
			});
		} else {
			console.log("Socket not connected yet!");
		}
	};

	const getNotifications = () => {
		return (
			<>
				{notified ? (
					<NotificationBellSvg fill={colorBell} />
				) : (
					<NotificationSvg fill={colorBell} />
				)}
			</>
		);
	};

	return (
		<>
			{!mobileSize && showModal && (
				<NotificationsModal onClose={() => setShowModal(false)} />
			)}
			<div
				id="joyride-ringbell"
				className="notification-area"
				onClick={() => {
					setShowModal(true);
					setNotified(false);
				}}
			>
				{getNotifications()}
			</div>
		</>
	);
};
