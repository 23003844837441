import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import { FileDown, FileUp } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HeaderSection } from "../../components";
import { OrderContext } from "../../context/OrderContext";
import { useViewVariable } from "../../context/ViewContext";
import { store } from "../../store";
import { handleStatusRequestTypes } from "../../utils/HandleTypes";
import "./styles.scss";

export const OrdersList = () => {
	const { list, onSearchParams, isLoading, total, offset, onPageChange } =
		useContext(OrderContext);

	const { i18n } = useTranslation();

	const language = i18n.language || store.getState().auth.language;

	const { mobileSize } = useViewVariable();

	useEffect(() => {
		onSearchParams("");
	}, []);

	const [legendSelect, setLegendSelect] = useState<string>("");
	const [disabled, setDisabled] = useState<boolean>(false);

	const navigate = useNavigate();

	const { partner, roles } = useSelector((state: any) => state?.auth);

	const { t } = useTranslation();

	const legends = {
		accomplished: {
			key: "ACCOMPLISHED",
			status: <FileUp width={"18px"} color="#048243" />,
			label: t("ordersPage.legends.accomplished")
		},
		received: {
			key: "RECEIVED",
			status: <FileDown width={"18px"} color="#5DAEFF" />,
			label: t("ordersPage.legends.received")
		}
	};

	const onSearch = (search: string) => {
		onSearchParams(search);
	};

	const statusChange = (id: number) => {
		if (partner.id === id) {
			return "received";
		} else {
			return "accomplished";
		}
	};

	const isTechnician = () => {
		return roles.length === 1 && roles.includes(1);
	};

	const handleSelect = (data: any) => {
		setDisabled(true);
		navigate(`/${language}/request/${data.id}`);
	};

	return (
		<>
			<article className="order-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={(search) => {
							onSearch(search);
						}}
						text={t("ordersPage.searchHeader")}
					/>
					{!mobileSize && !isTechnician() && (
						<HeaderSection.legend>
							{Object.values(legends).map((legend) => {
								return (
									<div
										className="legend"
										key={legend.key}
										onClick={() =>
											setLegendSelect((prevLegend) =>
												prevLegend === legend.key ? "" : legend.key
											)
										}
									>
										{legend.status}
										<span
											className={`${legendSelect === legend.key && "active"}`}
										>
											{legend.label}
										</span>
									</div>
								);
							})}
						</HeaderSection.legend>
					)}
					<HeaderSection.button
						text={t("ordersPage.buttonHeader")}
						onClick={() => navigate(`/${language}/newRequest`)}
					/>
					{mobileSize && !isTechnician() && (
						<HeaderSection.legend>
							{Object.values(legends).map((legend) => {
								return (
									<div
										className="legend"
										key={legend.key}
										onClick={() =>
											setLegendSelect((prevLegend) =>
												prevLegend === legend.key ? "" : legend.key
											)
										}
									>
										{legend.status}
										<span
											className={`${legendSelect === legend.key && "active"}`}
										>
											{legend.label}
										</span>
									</div>
								);
							})}
						</HeaderSection.legend>
					)}
				</HeaderSection.root>
				<div className="order-table">
					<GenericTable
						onRowClicked={(item: any) => !disabled && handleSelect(item)}
						data={
							list && [
								...list.map((order) => ({
									status: legends[statusChange(order?.dealerId)]?.status,
									id: order?.id,
									requester: order?.user?.contact,
									dealer: order?.dealer?.name,
									createdAt: new Date(order?.createdAt).toLocaleDateString(
										"pt-BR"
									),
									orderStatus: handleStatusRequestTypes(order?.status)
								}))
							]
						}
						columns={[
							{
								key: "status",
								header: "",
								width: !mobileSize ? "38px" : "0px"
							},
							{
								key: "id",
								header: t("ordersPage.tableColumns.code"),
								width: !mobileSize ? "calc( 15% - 38px)" : "calc(30% - 32px)"
							},
							{
								key: "createdAt",
								header: t("ordersPage.tableColumns.createdAt"),
								width: !mobileSize ? "12%" : "20%"
							},
							{
								key: "dealer",
								header: t("ordersPage.tableColumns.dealer"),
								width: !mobileSize ? "38%" : "20%",
								flex: true
							},
							{
								key: "requester",
								header: t("ordersPage.tableColumns.requester"),
								width: !mobileSize ? "20%" : "20%",
								flex: true
							},
							{
								key: "orderStatus",
								header: t("ordersPage.tableColumns.orderStatus"),
								width: !mobileSize ? "15%" : "20%"
							}
						]}
					/>
				</div>
				{!!list?.length && (
					<Paginator
						totalPages={total}
						currentPage={offset}
						onPageChange={onPageChange}
					/>
				)}
				{isLoading && <LoadingProgress />}
			</article>
		</>
	);
};
