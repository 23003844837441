import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import "./styles.scss";

type MessageBalloonProps = {
	status: string;
	onClose: () => void;
};

export const MessageBalloon = ({ status, onClose }: MessageBalloonProps) => {
	const isCancelled = status === "CANCELLATION_REQUEST";

	return (
		<div className="message-balloon-component">
			{isCancelled ? (
				<div className="message-balloon-info message-balloon-cancellation">
					<div className="message-balloon-header">
						<IoCloseSharp color="#c21618" size={20} onClick={onClose} />
					</div>
					<span>Esta solicitação está em processo de cancelamento.</span>
				</div>
			) : (
				<div className="message-balloon-info">
					<div className="message-balloon-header">
						<IoCloseSharp color="#025cb7" size={20} onClick={onClose} />
					</div>
					<span>
						O valor das peças reflete unicamente o valor de tabela da Prática.
						Não estão inclusos impostos, frete e acréscimos por custos
						operacionais da concessionária.
					</span>
				</div>
			)}
		</div>
	);
};
