import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { LuCalendarCheck2, LuCalendarClock, LuCalendarX } from "react-icons/lu";
import { HeaderSection } from "../../components";
import { PraticaContext } from "../../context/PraticaContext";
import {
	BRANCH_TYPES,
	dateProtheusToDate,
	handleType
} from "../../utils/protheus-functions";
import "./styles.scss";

export function PraticaBillets() {
	const { billets, isLoading, onChangeParams, onGetBillet, total, offset } =
		useContext(PraticaContext);
	const [legendSelect, setLegendSelect] = useState<string>("");

	const legends = {
		overdueInvoice: {
			key: "overdueInvoice",
			status: <LuCalendarX color="#C21618" size={18} />,
			label: t("PraticaBillets.legends.overdueInvoice")
		},
		today: {
			key: "today",
			status: <LuCalendarClock color="#5DAEFF" size={18} />,
			label: t("PraticaBillets.legends.today")
		},
		future: {
			key: "future",
			status: <LuCalendarCheck2 color="#048243" size={18} />,
			label: t("PraticaBillets.legends.future")
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const legend = urlParams.get("legends") as
			| "overdueInvoice"
			| "today"
			| "future";
		if (legend) {
			setTimeout(() => {
				setLegendSelect(legends[legend].key);
			}, 100);
		}
	}, []);

	useEffect(() => {
		onChangeParams({ status: legendSelect });
	}, [legendSelect]);

	const statusChange = (e1Vencto: string) => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth() + 1;
		const currentDay = currentDate.getDate();

		const year = parseInt(e1Vencto.substring(0, 4));
		const mes = parseInt(e1Vencto.substring(4, 6));
		const day = parseInt(e1Vencto.substring(6, 8));

		if (
			year > currentYear ||
			(year === currentYear && mes > currentMonth) ||
			(year === currentYear && mes === currentMonth && day > currentDay)
		) {
			return "future";
		} else if (
			year === currentYear &&
			mes === currentMonth &&
			day === currentDay
		) {
			return "today";
		} else {
			return "overdueInvoice";
		}
	};

	const onSearch = (term: string) => {
		onChangeParams({ term, offset: 1, status: legendSelect });
	};

	return (
		<>
			<article className="protheus-billet-list">
				<HeaderSection.root>
					<div className="protheus-billet-list-header">
						<HeaderSection.search
							onChangeValues={onSearch}
							text={t("PraticaBillets.searchHeader")}
						/>
						<HeaderSection.legend>
							{Object.values(legends).map((legend) => {
								return (
									<div
										className="legend"
										key={legend.key}
										onClick={() =>
											setLegendSelect((prevLegend) =>
												prevLegend === legend.key ? "" : legend.key
											)
										}
									>
										{legend.status}
										<span
											className={`${legendSelect === legend.key && "active"}`}
										>
											{legend.label}
										</span>
									</div>
								);
							})}
						</HeaderSection.legend>
					</div>
				</HeaderSection.root>
				<div className="protheus-billet-list-table">
					<GenericTable
						onRowClicked={onGetBillet}
						data={billets.map((item) => ({
							...item,
							id: item.rECNO,
							status: legends[statusChange(item?.e1Vencto)]?.status,
							issueDate: dateProtheusToDate(item.e1Emissao)?.toLocaleDateString(
								"pt-BR"
							),
							dueDate: dateProtheusToDate(item.e1Vencto)?.toLocaleDateString(
								"pt-BR"
							),
							invoiceTitle: `${item.e1Num} - ${item.e1Prefixo}`,
							total: item.e1Saldo.toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							}),
							branch: handleType({
								value: item.e1Filorig,
								types: BRANCH_TYPES
							}),
							order: item.e1Prefixo === "FAT" ? "-" : item.e1Pedido
						}))}
						columns={[
							{
								key: "status",
								header: "",
								width: "38px"
							},
							{
								key: "issueDate",
								header: t("PraticaBillets.tableColumns.issueDate"),
								width: "10%"
							},
							{
								key: "branch",
								header: t("PraticaBillets.tableColumns.branch"),
								width: "10%"
							},
							{
								key: "order",
								header: t("PraticaBillets.tableColumns.order"),
								width: "10%"
							},
							{
								key: "invoiceTitle",
								header: t("PraticaBillets.tableColumns.invoiceTitle"),
								width: "15%"
							},
							{
								key: "e1Xcodbar",
								header: t("PraticaBillets.tableColumns.e1Xcodbar"),
								width: "calc(35% - 38px)"
							},
							{
								key: "total",
								header: t("PraticaBillets.tableColumns.total"),
								width: "10%"
							},
							{
								key: "dueDate",
								header: t("PraticaBillets.tableColumns.dueDate"),
								width: "10%"
							}
						]}
					/>
				</div>
				<div className="protheus-billet-paginator">
					<Paginator
						currentPage={offset}
						totalPages={total || 1}
						onPageChange={(value) =>
							onChangeParams({ offset: value, status: legendSelect })
						}
					/>
				</div>
			</article>
			{isLoading && <LoadingProgress />}
		</>
	);
}
