import { t } from "i18next";
import { Boxes, FilePenLine, PackagePlus, SearchX } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { HeaderSection } from "../../components";
import { ManageProductModal } from "../../components/ManageProductModal";
import { Tag } from "../../components/Tag";
import { AuthContext } from "../../context";

import {
	EmptyList,
	Input,
	LoadingProgress,
	Paginator,
	Select
} from "@praticabr/ppsa";
import { LuAlertTriangle } from "react-icons/lu";
import { InfoExpansive } from "../../components/InfoExpansive";
import { ModalAlternativeProduct } from "../../components/ModalAlternativeProduct";
import { Sb1010sContext } from "../../context/Sb1010sContext";
import { useViewVariable } from "../../context/ViewContext";
import "./styles.scss";

export function CatalogEditorPage() {
	const {
		sb1010list,
		onUpdateList,
		tags,
		subTags,
		onSearchParams,
		onPageChange,
		total,
		offset,
		isLoading,
		selectedTags,
		setSelectedTags,
		setSelectedSubTags,
		selectedSubTags,
		updateParams
	} = useContext(Sb1010sContext);
	const { screenSize } = useViewVariable();
	const { roles } = useContext(AuthContext).user;
	const [selectedItem, setSelectedItem] = useState<any>(null);
	const [showAlternativeProduct, setShowAlternativeProduct] = useState<any>();

	const isAdmin = roles?.includes(3) || roles?.includes(4);

	useEffect(() => {
		if (!selectedItem) onUpdateList();
	}, [selectedItem]);

	return (
		<>
			<div className="catalog-editor-page">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearchParams}
						text={t("catalogPage.searchPlaceholder")}
					/>
					<div className="catalog-editor-selects">
						<div className="select-tags">
							<Select
								id="tag"
								autoComplete="off"
								onSelectOptions={(option) => {
									updateParams(1, "offset");
									setSelectedTags(option?.map((value) => Number(value)));
								}}
								selectedOption={selectedTags?.map((tag) => tag?.toString())}
								placeholder={t("catalogPage.selectTagPlaceholder")}
								options={
									tags
										?.map((tag) => ({
											label: tag?.label,
											value: String(tag?.id)
										}))
										?.sort((a, b) => a?.label?.localeCompare(b?.label)) || []
								}
							/>
						</div>
						<div className="select-sub-tags">
							<Select
								id="sub-tag"
								autoComplete="off"
								onSelectOptions={(option) => {
									updateParams(1, "offset");
									setSelectedSubTags(option?.map((value) => Number(value)));
								}}
								selectedOption={selectedSubTags?.map((subTag) =>
									subTag?.toString()
								)}
								placeholder={t("catalogPage.selectSubTagPlaceholder")}
								options={
									subTags
										?.map((subTag) => ({
											label: subTag?.label,
											value: String(subTag?.id)
										}))
										?.sort((a, b) => a?.label?.localeCompare(b?.label)) || []
								}
							/>
						</div>
						{(!!selectedTags?.length || !!selectedSubTags?.length) && (
							<div className="erase-action">
								<SearchX
									size={24}
									color="#004992"
									onClick={() => {
										setSelectedTags([]);
										setSelectedSubTags([]);
									}}
								/>
							</div>
						)}
					</div>
				</HeaderSection.root>
				<div className="products">
					{sb1010list?.length ? (
						sb1010list?.map((product) => (
							<div className="product" key={product?.b1Cod}>
								<div className="product-image">
									{product?.details?.files?.length > 0 ? (
										product?.details?.files
											?.sort((a, b) => a?.id - b?.id)
											?.map(
												(file: {
													id: React.Key | null | undefined;
													url: string | undefined;
												}) => (
													<img
														key={file?.id}
														src={file?.url}
														alt={product?.b1Cod}
														onClick={() => window?.open(file?.url)}
													/>
												)
											)
									) : (
										<div className="no-image">
											<Boxes size={48} color="#004992" />
										</div>
									)}
								</div>
								<div className="product-info">
									<div
										className="header"
										onClick={() =>
											product?.sgi010?.length > 0 &&
											setShowAlternativeProduct(product)
										}
									>
										<h1>{`${product?.b1Cod} - ${product?.b1Desc}`}</h1>
										{product?.sgi010?.length > 0 && (
											<>
												{screenSize?.width <= 900 ? (
													<LuAlertTriangle title="Produto Com Código Alternativo" />
												) : (
													<Tag
														label="Produto com código alternativo"
														color="#C21618"
													/>
												)}
											</>
										)}
									</div>
									<div className="contain">
										<div className="info">
											<InfoExpansive
												label={"Aplicação:"}
												text={product?.details?.application}
											/>
										</div>
										<div className="info">
											<InfoExpansive
												label={"Observação:"}
												text={product?.details?.observation}
											/>
										</div>
									</div>
									<div className="contain">
										<div className="procedures">
											{product?.details?.procedures?.map((procedure, index) => (
												<a
													title={procedure?.name}
													key={index}
													href={`${procedure?.link}`}
													target="_blank"
													rel="noreferrer"
												>
													{procedure?.name}
												</a>
											))}
										</div>
									</div>
									<div className="footer">
										<div className="tags">
											{product?.details?.tags?.map((tagId, index) => {
												const currentTag = tags?.filter(
													(tag) => tag?.id === tagId
												);
												return (
													<Tag
														selected={selectedTags?.includes(currentTag[0]?.id)}
														label={currentTag[0]?.label}
														color={currentTag[0]?.color}
														key={currentTag[0]?.id + product?.b1Cod + index}
													/>
												);
											})}
										</div>
										<div className="sub-tags">
											{product?.details?.subTags?.map((subTagId, index) => {
												const currentSubTag = subTags?.filter(
													(tag) => tag?.id === subTagId
												);
												return (
													<Tag
														selected={selectedSubTags?.includes(
															currentSubTag[0]?.id
														)}
														label={currentSubTag[0]?.label}
														color={currentSubTag[0]?.color}
														key={currentSubTag[0]?.id + product?.b1Cod + index}
													/>
												);
											})}
										</div>
									</div>
								</div>
								<div className="product-action">
									{isAdmin ? (
										<>
											<h1>
												{(product?.da1010?.find(
													(item) => item?.da1Codtab === "C20"
												)?.da1Prcven &&
													Number(
														product?.da1010?.find(
															(item) => item?.da1Codtab === "C20"
														)?.da1Prcven
													)?.toLocaleString("pt-BR", {
														style: "currency",
														currency: "BRL"
													})) ||
													"Consultar"}
											</h1>
											<FilePenLine
												size={32}
												color="#004687"
												onClick={() => setSelectedItem(product)}
											/>
										</>
									) : (
										<>
											<div className="product-quantity-input">
												<Input type="number" name="quantity" variant="light" />
											</div>
											<PackagePlus color="#004687" size={32} />
										</>
									)}
								</div>
							</div>
						))
					) : (
						<EmptyList />
					)}
				</div>
				<div className="catalog-editor-paginator">
					<Paginator
						currentPage={offset}
						totalPages={total || 1}
						onPageChange={(value) => onPageChange(value)}
					/>
				</div>
			</div>
			{selectedItem && (
				<ManageProductModal
					product={selectedItem}
					onClose={() => setSelectedItem(null)}
				/>
			)}
			{showAlternativeProduct && (
				<ModalAlternativeProduct
					item={showAlternativeProduct}
					onClose={() => setShowAlternativeProduct(undefined)}
				/>
			)}
			{isLoading && <LoadingProgress />}
		</>
	);
}
