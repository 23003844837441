import { GenericTable, LoadingProgress, Paginator } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderSection } from "../../components";
import { ManagePartnerModal } from "../../components/ManagePartnerModal";
import { RegisterPartnerModal } from "../../components/RegisterPartnerModal";
import { PartnerContext } from "../../context/PartnerContext";
import { useViewVariable } from "../../context/ViewContext";
import { store } from "../../store";
import "./style.scss";

export const Partners = () => {
	const { mobileSize } = useViewVariable();
	const { list, isLoading, onPageChange, offset, total, onSearchParams } =
		useContext(PartnerContext);
	const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false);
	const [selectedPartner, setSelectedPartner] = useState<any>();
	const [showUpdateUserModal, setShowUpdateUserModal] =
		useState<boolean>(false);
	const { t } = useTranslation();
	const user = store.getState().auth;
	const adminCS = user.roles.includes(4);

	useEffect(() => {
		onSearchParams("");
	}, []);

	return (
		<>
			<div className="partners">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={(value) => onSearchParams(value)}
						text={t("partnersPage.searchHeader")}
					/>
					<HeaderSection.button
						text={t("partnersPage.buttonHeader")}
						onClick={() => setShowNewUserModal(true)}
					/>
				</HeaderSection.root>
				<div className="partners-table">
					<GenericTable
						data={(adminCS ? list : list).map((partner) => {
							return {
								...partner,
								name: partner.name,
								code: partner.code,
								table: partner.table
							};
						})}
						columns={[
							{
								key: "name",
								header: t("partnersPage.tableColumns.name"),
								width: !mobileSize ? "50%" : "35%",
								flex: true
							},
							{
								key: "code",
								header: t("partnersPage.tableColumns.code"),
								width: !mobileSize ? "20%" : "25%"
							},
							{
								key: "table",
								header: t("partnersPage.tableColumns.table"),
								width: "30%"
							}
						]}
						onRowClicked={(partner) => {
							setShowUpdateUserModal(true);
							setSelectedPartner(partner);
						}}
					/>
				</div>
				<Paginator
					totalPages={total}
					currentPage={offset}
					onPageChange={(value) => onPageChange(value)}
				/>
				{showNewUserModal && (
					<RegisterPartnerModal onClose={() => setShowNewUserModal(false)} />
				)}
				{showUpdateUserModal && selectedPartner?.id && (
					<ManagePartnerModal
						partner={selectedPartner}
						onClose={() => setShowUpdateUserModal(false)}
					/>
				)}
				{isLoading && <LoadingProgress />}
			</div>
		</>
	);
};
