import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input, LoadingProgress, Modal, Select } from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCirclePlus } from "react-icons/fa6";
import { LuTrash2 } from "react-icons/lu";
import { toast } from "react-toastify";
import { z } from "zod";
import { Sb1010sContext } from "../../context/Sb1010sContext";
import { UploadFile } from "../../models";
import { Sb1010sCustomList } from "../../models/usecases/sb1010s-custom-list";
import { deleteFile, deleteUpload } from "../../services";
import { Upload } from "../UploadFilesWithoutModal";
import "./styles.scss";

const procedureSchema = z.object({
	name: z.string().min(1),
	link: z.string().url(),
	procedureId: z.number().optional()
});

const upsertProductSchema = z.object({
	activated: z.boolean(),
	application: z.string(),
	code: z.string().min(3).max(15),
	observation: z.string(),
	tags: z.array(z.number()),
	subTags: z.array(z.number()),
	files: z.array(z.any()),
	procedures: z.array(procedureSchema),
	deletingProcedures: z.array(z.number()).optional()
});

export type upsertProductSchema = z.infer<typeof upsertProductSchema>;

type Props = {
	onClose: () => void;
	product: Sb1010sCustomList.Return["list"][0];
};

export const ManageProductModal = ({ onClose, product }: Props) => {
	const { isLoading, tags, subTags, onUpsertProduct } =
		useContext(Sb1010sContext);
	const [fileDeleted, setFileDeleted] = useState<any[]>([]);
	const [selectedTags, setSelectedTags] = useState<number[]>(
		product.details?.tags?.map((tag) => Number(tag)) || []
	);
	const [selectedSubTags, setSelectedSubTags] = useState<number[]>(
		product.details?.subTags?.map((tag) => Number(tag)) || []
	);
	const [deletingProcedures, setDeletingProcedures] = useState<any[]>([]);

	const { t } = useTranslation();

	const activatedOptions = [
		{
			label: t("manageProductModal.activatedSelect.options.true"),
			value: "true"
		},
		{
			label: t("manageProductModal.activatedSelect.options.false"),
			value: "false"
		}
	];

	const {
		getValues,
		handleSubmit,
		setValue,
		register,
		formState: { errors },
		getFieldState,
		control
	} = useForm<upsertProductSchema>({
		defaultValues: {
			activated: !!product?.details?.activated,
			application: product?.details?.application,
			code: product?.b1Cod,
			observation: product?.details?.observation,
			tags: product?.details?.tags?.map((tag) => Number(tag)) || [],
			subTags: product?.details?.subTags?.map((tag) => Number(tag)) || [],
			files: product?.details?.files || [],
			procedures: product?.details?.procedures || []
		},
		resolver: zodResolver(upsertProductSchema)
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "procedures"
	});

	const handleDeleteProcedure = (data: any, index: number) => {
		remove(index);
		data?.procedureId &&
			setDeletingProcedures([...deletingProcedures, data.procedureId]);
	};

	useEffect(() => {
		const previewFileContainer = document.getElementById(
			"upload-file-container"
		);
		if (previewFileContainer && product?.details?.files) {
			product?.details?.files
				?.sort((a, b) => b?.id - a?.id)
				?.forEach((file) => {
					const div = document.createElement("div");
					div.className = "preview-file-container";
					div.innerHTML =
						`<div id="preview-file-container-${file?.id}" class="preview-file-container"> ` +
						`	<div class="preview-files"> ` +
						`		<img src="${file?.url}" alt="image"> ` +
						`		<div id="preview-file-container-remove" class="upload-file-list-item-remove"> ` +
						`			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="transparent" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash2 "><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path><line x1="10" x2="10" y1="11" y2="17"></line><line x1="14" x2="14" y1="11" y2="17"></line></svg> ` +
						`		</div> ` +
						`	</div> ` +
						`</div>`;
					previewFileContainer.insertBefore(
						div,
						previewFileContainer.firstChild
					);

					const removeButton = div.querySelector(
						"#preview-file-container-remove"
					);
					removeButton?.addEventListener("click", () =>
						handleDeleteFile(file.id)
					);
				});
		}
	}, [product]);

	const handleDeleteFile = async (fileId: number) => {
		await deleteFile(fileId)
			.then(() => {
				const previewFileContainer = document.getElementById(
					`preview-file-container-${fileId}`
				);
				previewFileContainer?.setAttribute("style", "display: none");
			})
			.catch(() => toast.error("Erro ao deletar imagem"));
	};

	const filesForm = useForm<UploadFile, "files">();
	const filesFieldArray = useFieldArray({
		control: filesForm?.control,
		name: "files"
	});

	const onFileDeleted = (file: any) => {
		setFileDeleted((files) => [...files, file]);
	};

	const onSubmit = async (data: upsertProductSchema) => {
		await filesForm?.handleSubmit(() => {})();
		const filesErros = filesForm?.getFieldState("files").error;

		if (fileDeleted?.length > 0) {
			fileDeleted?.forEach((file) => {
				deleteUpload(file);
			});
		}
		if (filesErros) {
			filesErros && toast.error(t("newSaleRecordModal.toast.filesError"));
		} else {
			const files = filesFieldArray.fields;

			onUpsertProduct(
				{
					...data,
					files: files?.map((file) => ({
						description: file?.description,
						extension: file?.type,
						url: file?.url
					})),
					tags: selectedTags,
					subTags: selectedSubTags,
					deletingProcedures
				},
				onClose
			);
		}
	};

	const addProcedureStyle: React.CSSProperties = {
		justifyContent: fields?.length > 0 ? "flex-start" : "center"
	};

	return (
		<>
			<Modal.root>
				<Modal.header onClose={onClose} title={t("manageProductModal.title")} />
				<Modal.body>
					<Modal.body.main>
						<div className="product-modal">
							<div className="form-container">
								<h1>{`${product?.b1Cod} - ${product?.b1Desc}`}</h1>
								<Upload.root>
									<Upload.upload
										filesForm={filesForm}
										fieldFiles={filesFieldArray}
										onFileDeleted={onFileDeleted}
									/>
								</Upload.root>
								<div className="form-item">
									<label htmlFor="activated" id="label-activated">
										{t("manageProductModal.activatedSelect.label")}
									</label>
									<Select
										id="activated"
										autoComplete="off"
										options={activatedOptions}
										{...register("activated")}
										onSelectOptions={(option) =>
											setValue("activated", option[0] === "true")
										}
										selectedOption={
											getValues("activated") ? ["true"] : ["false"]
										}
										placeholder={t(
											"manageProductModal.activatedSelect.placeholder"
										)}
									/>
								</div>
								<div className="form-item" />
								<div className="form-item">
									<label htmlFor="tag" id="label-tag">
										{t("manageProductModal.tagSelect.label")}
									</label>
									<Select
										id="tag"
										checkbox
										autoComplete="off"
										onSelectOptions={(option) => {
											setSelectedTags(option?.map((value) => Number(value)));
											setValue(
												"tags",
												option?.map((value) => Number(value))
											);
										}}
										{...register("tags")}
										selectedOption={selectedTags?.map((tag) => tag?.toString())}
										placeholder={t("manageProductModal.tagSelect.placeholder")}
										options={
											tags?.map((tag) => ({
												label: tag?.label,
												value: String(tag?.id)
											})) || []
										}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="subTags" id="label-subTags">
										{t("manageProductModal.subTagsSelect.label")}
									</label>
									<Select
										checkbox
										id="subTag"
										autoComplete="off"
										onSelectOptions={(option) => {
											setSelectedSubTags(option?.map((value) => Number(value)));
											setValue(
												"subTags",
												option?.map((value) => Number(value))
											);
										}}
										{...register("subTags")}
										selectedOption={selectedSubTags?.map((subTag) =>
											subTag?.toString()
										)}
										placeholder={t(
											"manageProductModal.subTagsSelect.placeholder"
										)}
										options={
											subTags?.map((subTag) => ({
												label: subTag?.label,
												value: String(subTag?.id)
											})) || []
										}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="application">
										{t("manageProductModal.applicationInput.label")}
									</label>
									<Input
										variant="light"
										errors={errors?.application}
										defaultValue={product?.details?.application}
										{...register("application")}
										placeholder={t(
											"manageProductModal.applicationInput.placeholder"
										)}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="observation">
										{t("manageProductModal.observationInput.label")}
									</label>
									<Input
										variant="light"
										errors={errors?.observation}
										defaultValue={product?.details?.observation}
										{...register("observation")}
										placeholder={t(
											"manageProductModal.observationInput.placeholder"
										)}
									/>
								</div>
								{fields.map((field, index) => (
									<React.Fragment key={field?.id}>
										<div key={field?.id} className="form-item">
											<label htmlFor="name">
												{t("manageProductModal.procedureName.label")}
											</label>
											<Input
												variant="light"
												defaultValue={product?.details?.observation}
												{...register(`procedures.${index}.name`)}
												placeholder={t(
													"manageProductModal.procedureName.placeholder"
												)}
												errors={getFieldState(`procedures.${index}.name`).error}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="link">
												{t("manageProductModal.procedureLink.label")}
											</label>
											<Input
												variant="light"
												defaultValue={product?.details?.observation}
												{...register(`procedures.${index}.link`)}
												placeholder={t(
													"manageProductModal.procedureLink.placeholder"
												)}
												errors={getFieldState(`procedures.${index}.link`).error}
											/>
											<div
												className="remove-procedure"
												onClick={() => handleDeleteProcedure(field, index)}
											>
												<LuTrash2 width={20} height={20} />
											</div>
										</div>
									</React.Fragment>
								))}
								<div className="form-item">
									<div
										className="add-procedure"
										style={addProcedureStyle}
										onClick={() => append({ name: "", link: "" })}
									>
										<FaCirclePlus width={20} height={20} color="#004992" />
										<span>Adicionar procedimento</span>
									</div>
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="manage-product-modal-actions">
							<Button
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit(onSubmit)}
								title={t("manageProductModal.submitAction")}
							/>
							<Button
								size="lg"
								onClick={onClose}
								variant="cancellation"
								title={t("manageProductModal.cancelAction")}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{isLoading && <LoadingProgress />}
		</>
	);
};
