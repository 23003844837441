import { ConfirmModal, Input } from "@praticabr/ppsa";
import React, { useEffect, useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import TrashIcon from "../../../../../assets/SvgIcons/TrashIcon";
import { ModalAlternativeProduct } from "../../../../../components/ModalAlternativeProduct";
import { orderCreateSchema } from "../../../../../schemas/orderCreateSchema";
import "./styles.scss";

type OrderItemsContentEditingModeMobileProps = {
	order?: any;
	fieldArray: UseFieldArrayReturn<any, "items", "id">;
	isEditing?: boolean;
	handleDeleteItem?: (item: any) => void;
};

export const OrderItemsContentEditingModeMobile = ({
	fieldArray,
	handleDeleteItem,
	isEditing = false,
	order
}: OrderItemsContentEditingModeMobileProps) => {
	const [itemDeleting, setItemDeleting] = useState<any>();
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
		useState<boolean>(false);
	const [showAlternativeProduct, setShowAlternativeProduct] = useState<any>();

	const {
		register,
		setValue,
		getValues,
		getFieldState,
		formState: { errors }
	} = useFormContext<orderCreateSchema>();

	const { fields, remove } = fieldArray;

	const handleChangesRemove = (item: any, index: number) => {
		setShowConfirmDeleteModal(true);
		setItemDeleting({ item, index });
	};

	const handleOnConfirmDelete = () => {
		if (itemDeleting) {
			setShowConfirmDeleteModal(false);
			if (handleDeleteItem) {
				handleDeleteItem(itemDeleting?.item);
			}
			onDeleteItem(itemDeleting.index);
		}
	};

	const handleOnCancelConfirmDelete = () => {
		setShowConfirmDeleteModal(false);
		setItemDeleting(undefined);
	};

	const onChangePriceTotal = (index: number, value: number, option: string) => {
		if (option === `items.${index}.amount`) {
			const price_total = value * getValues(`items.${index}.price`);
			setValue(
				`items.${index}.totalPrice`,
				Number(price_total.toFixed(2)) || 0
			);
		}
		if (option === `items.${index}.price`) {
			const price_total = value * getValues(`items.${index}.amount`);
			setValue(
				`items.${index}.totalPrice`,
				Number(price_total.toFixed(2)) || 0
			);
		}
	};

	const onDeleteItem = (index: number) => {
		remove(index);
	};

	useEffect(() => {
		const elementItem = document.querySelector(
			".items-information"
		) as HTMLElement;
		!order &&
			elementItem &&
			elementItem.scrollIntoView({ behavior: "smooth", block: "start" });
	}, []);

	const resolveNanNumber = (number: any): number =>
		typeof number === "string" ? 0 : number;

	return (
		<div className="order-itens-mobile">
			{fields?.map((item: any, index) => {
				setValue(`items.${index}.price`, resolveNanNumber(item.price));
				return (
					!item?.deleted && (
						<div className="order-itens-mobile-line">
							<div className="order-itens-mobile-line-item-product">
								<div className="order-itens-mobile-line-item">
									<header>Produto</header>
									<span>
										{item?.code} - {item?.description}
									</span>
								</div>
								<div className="order-itens-mobile-line-item">
									<header> </header>
									<span>
										<div onClick={() => handleChangesRemove(item, index)}>
											<TrashIcon width={"auto"} fill="#5DAEFF" />
										</div>
									</span>
								</div>
							</div>
							<div className="order-itens-mobile-line-item-values">
								<div className="order-itens-mobile-line-item">
									<header>Quant.</header>
									<span>
										<Input
											variant="light"
											type="number"
											defaultValue={item?.amount}
											{...register(`items.${index}.amount`, {
												required: true,
												min: 0.01,
												valueAsNumber: true
											})}
											onChange={(event: any) => {
												onChangePriceTotal(
													index,
													Number(event.target.value),
													`items.${index}.amount`
												);
											}}
											min={0}
											maxLength={9}
											errors={getFieldState(`items.${index}.amount`).error}
										/>
									</span>
								</div>
								<div className="order-itens-mobile-line-item">
									<header>Valor unitário (R$)</header>
									<span>
										<Input
											money={true}
											type="number"
											variant="light"
											defaultValue={getValues(`items.${index}.price`)}
											disabled={true}
											{...register(`items.${index}.price`, {
												required: true,
												valueAsNumber: true
											})}
											errors={getFieldState(`items.${index}.price`).error}
										/>
									</span>
								</div>
							</div>
							<div className="order-itens-mobile-line-item-actions">
								<div className="order-itens-mobile-line-item">
									<header>Total merc. (R$)</header>
									<span>
										<Input
											money={true}
											variant="light"
											type="number"
											disabled={true}
											defaultValue={getValues(`items.${index}.totalPrice`)}
											{...register(`items.${index}.totalPrice`)}
										/>
									</span>
								</div>
								<div className="order-itens-mobile-line-item">
									<header>IPI</header>
									<span>{item?.product?.b1Ipi}</span>
								</div>
							</div>
						</div>
					)
				);
			})}
			{showConfirmDeleteModal && (
				<ConfirmModal
					title="EXCLUIR ITEM"
					confirmLabel="SIM"
					cancelLabel="NÃO"
					onCancel={handleOnCancelConfirmDelete}
					onConfirm={handleOnConfirmDelete}
					buttonWidth={"68px"}
				>
					<span>Deseja excluir o item {itemDeleting?.item.b1Cod}?</span>
				</ConfirmModal>
			)}
			{showAlternativeProduct && (
				<ModalAlternativeProduct
					item={showAlternativeProduct}
					onClose={() => setShowAlternativeProduct(undefined)}
				/>
			)}
		</div>
	);
};
