import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from "react";
import { toast } from "react-toastify";
import { upsertProductSchema } from "../components/ManageProductModal";
import { Sb1010sCustomList } from "../models/usecases/sb1010s-custom-list";
import {
	createSubTag,
	createTag,
	deleteSubTag,
	deleteTag,
	findActiveSubTags,
	findActiveTags,
	getListSubTags,
	getListTags,
	getSb1010,
	getSubTags,
	getTags,
	updateSubTag,
	updateTag,
	upsertProduct
} from "../services";
import ListParams, {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from "../utils/ContextUtils";

export interface ContextType {
	sb1010list: Sb1010sCustomList.Return["list"];
	tags: any[];
	tagsList: any[];
	subTags: any[];
	subTagsList: any[];
	selectedTags: number[];
	setSelectedTags: React.Dispatch<React.SetStateAction<number[]>>;
	selectedSubTags: number[];
	setSelectedSubTags: React.Dispatch<React.SetStateAction<number[]>>;
	isLoading: boolean;
	limit: number;
	total: number;
	offset: number;
	onUpsertProduct: (data: upsertProductSchema, callback?: () => void) => void;
	onUpdateList: () => void;
	onSearchParams: (searchTerm: string) => void;
	onPageChange: (page: number) => void;
	onGetTagsList: () => void;
	updateParams: (value: FilterValue, field: FilterField) => void;
	onCreateTag: (data: any, callback?: () => void) => void;
	onDeleteTag: (data: any, callback?: () => void) => void;
	onUpdateTag: (data: any, callback?: () => void) => void;
	onGetSubTagsList: () => void;
	onCreateSubTag: (data: any, callback?: () => void) => void;
	onDeleteSubTag: (data: any, callback?: () => void) => void;
	onUpdateSubTag: (data: any, callback?: () => void) => void;
	countProductsWithSubTag: number;
	onCountProductsWithSubTag: (id: number) => void;
	countProductsWithTag: number;
	onCountProductsWithTag: (id: number) => void;
	onSearchParamsTag: (searchTerm: string, type: "TAG" | "SUBTAG") => void;
}

export const Sb1010sContext = createContext<ContextType>({
	sb1010list: [],
	tags: [],
	tagsList: [],
	countProductsWithTag: 0,
	subTagsList: [],
	countProductsWithSubTag: 0,
	subTags: [],
	selectedTags: [],
	setSelectedTags: () => {},
	selectedSubTags: [],
	setSelectedSubTags: () => {},
	isLoading: false,
	limit: 20,
	total: 1,
	offset: 1,
	onUpsertProduct: () => {},
	onUpdateList: () => {},
	onPageChange: (page: number) => {},
	onSearchParams: (searchTerm: string) => {},
	updateParams: (value: FilterValue, field: FilterField) => {},
	onGetTagsList: () => {},
	onCreateTag: (data: any, callback?: () => void) => {},
	onDeleteTag: (data: any, callback?: () => void) => {},
	onUpdateTag: (data: any, callback?: () => void) => {},
	onCountProductsWithTag: (id: number) => {},
	onGetSubTagsList: () => {},
	onCreateSubTag: (data: any, callback?: () => void) => {},
	onDeleteSubTag: (data: any, callback?: () => void) => {},
	onUpdateSubTag: (data: any, callback?: () => void) => {},
	onCountProductsWithSubTag: (id: number) => {},
	onSearchParamsTag: (searchTerm: string, type: "TAG" | "SUBTAG") => {}
});

const defaultParams = defaultListParams();

export const Sb1010sProvider = ({ children }: { children: ReactNode }) => {
	const [sb1010list, setSb1010List] = useState<
		Sb1010sCustomList.Return["list"]
	>([]);
	const [tags, setTags] = useState<any[]>([]);
	const [tagsList, setTagsList] = useState<any[]>([]);
	const [subTagsList, setSubTagsList] = useState<any[]>([]);
	const [subTags, setSubTags] = useState<any[]>([]);
	const [selectedTags, setSelectedTags] = useState<number[]>([]);
	const [selectedSubTags, setSelectedSubTags] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [countProductsWithSubTag, setCountProductsWithSubTag] =
		useState<number>(0);
	const [countProductsWithTag, setcountProductsWithTag] = useState<number>(0);
	const params = useRef<ListParams>(defaultParams);

	useEffect(() => {
		if (!tags.length) {
			onGetTags();
		}
		if (!subTags.length) {
			onGetSubTags();
		}
		onGetSb1010s();
	}, [selectedTags, selectedSubTags]);

	const onGetSb1010s = () => {
		const { limit, filter, offset, term, total } = { ...params.current };

		setIsLoading(true);
		getSb1010(
			{ limit, term, filter, offset, total },
			selectedTags,
			selectedSubTags
		)
			.then((response) => {
				setSb1010List(response.data.list);
				updateParams(
					Math.ceil(response.data.count / params.current.limit),
					"total"
				);
			})
			.catch(() => {
				toast.error("Erro ao buscar produtos");
				clearParams(true);
			})
			.finally(() => setIsLoading(false));
	};

	const onGetTags = () => {
		getTags()
			.then((response) => {
				setTags([{ id: "", label: "" }, ...response.data]);
			})
			.catch(() => {
				toast.error("Erro ao buscar tags");
			});
	};

	const onGetSubTags = () => {
		getSubTags()
			.then((response) => {
				setSubTags([{ id: "", label: "" }, ...response.data]);
			})
			.catch(() => {
				toast.error("Erro ao buscar tags");
			});
	};

	const onUpsertProduct = (
		data: upsertProductSchema,
		callback?: () => void
	) => {
		if (isLoading) return;

		setIsLoading(true);
		upsertProduct(data)
			.then(async () => {
				toast.success("Produto atualizado com sucesso!");
				callback && callback();
			})
			.catch(() => toast.error("Erro ao atualizar o produto"))
			.finally(() => setIsLoading(false));
	};

	const updateParams = (value: FilterValue, field: FilterField) => {
		params.current = updateListParams(value, field, params.current);
	};

	const clearParams = (clearAll = false) => {
		params.current = clearListParams(params.current, clearAll);
	};

	const onSearchParams = (searchTerm: string) => {
		updateParams(searchTerm, "search");
		updateParams(1, "offset");
		onGetSb1010s();
	};

	const onSearchParamsTag = (searchTerm: string, type: "TAG" | "SUBTAG") => {
		updateParams(searchTerm, "search");
		type === "TAG" ? onGetTagsList() : onGetSubTagsList();
	};

	const onPageChange = (page: number) => {
		updateParams(page, "offset");
		onGetSb1010s();
	};

	const onGetTagsList = () => {
		const { term } = { ...params.current };
		setIsLoading(true);
		getListTags({ term: term || "" })
			.then((response) => {
				setTagsList(response.data);
			})
			.catch((error) => {
				toast.error("Erro ao buscar tags");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onCreateTag = (data: any, callback?: () => void) => {
		setIsLoading(true);
		createTag(data)
			.then(() => {
				toast.success("TAG criada com sucesso");
				callback && callback();
			})
			.catch((error) => {
				toast.error("Erro ao criar TAG");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onDeleteTag = (data: any, callback?: () => void) => {
		setIsLoading(true);
		deleteTag(data)
			.then(() => {
				toast.success("TAG excluída com sucesso");
				callback && callback();
			})
			.catch((error) => {
				toast.error("Erro ao excluir TAG");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onUpdateTag = (data: any, callback?: () => void) => {
		setIsLoading(true);
		updateTag(data)
			.then(() => {
				toast.success("TAG atualizada com sucesso");
				callback && callback();
			})
			.catch((error) => {
				toast.error("Erro ao atualizar TAG");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onCountProductsWithTag = (id: number) => {
		setIsLoading(true);
		findActiveTags(id)
			.then((response) => setcountProductsWithTag(response?.data?.length))
			.catch((error) => {
				toast.error("Erro ao buscar tags ativas");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onGetSubTagsList = () => {
		const { term } = { ...params.current };
		setIsLoading(true);
		getListSubTags({ term: term || "" })
			.then((response) => {
				setSubTagsList(response.data);
			})
			.catch((error) => {
				toast.error("Erro ao buscar subtags");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onCreateSubTag = (data: any, callback?: () => void) => {
		setIsLoading(true);
		createSubTag(data)
			.then(() => {
				toast.success("SUBTAG criada com sucesso");
				callback && callback();
			})
			.catch((error) => {
				toast.error("Erro ao criar SUBTAG");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onDeleteSubTag = (data: any, callback?: () => void) => {
		setIsLoading(true);
		deleteSubTag(data)
			.then(() => {
				toast.success("TAG excluída com sucesso");
				callback && callback();
			})
			.catch((error) => {
				toast.error("Erro ao excluir TAG");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onUpdateSubTag = (data: any, callback?: () => void) => {
		setIsLoading(true);
		updateSubTag(data)
			.then(() => {
				toast.success("SUBTAG atualizada com sucesso");
				callback && callback();
			})
			.catch((error) => {
				toast.error("Erro ao atualizar SUBTAG");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const onCountProductsWithSubTag = (id: number) => {
		setIsLoading(true);
		findActiveSubTags(id)
			.then((response) => setCountProductsWithSubTag(response?.data?.length))
			.catch((error) => {
				toast.error("Erro ao buscar subtags ativas");
				console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	const value = {
		sb1010list,
		tags,
		tagsList,
		subTagsList,
		countProductsWithSubTag,
		countProductsWithTag,
		subTags,
		selectedTags,
		setSelectedTags,
		selectedSubTags,
		setSelectedSubTags,
		isLoading,
		...params.current,
		onUpsertProduct,
		onUpdateList: () => {
			onGetSb1010s();
		},
		onSearchParams,
		onPageChange,
		onGetTagsList,
		onCreateTag,
		onDeleteTag,
		updateParams,
		onUpdateTag,
		onGetSubTagsList,
		onCreateSubTag,
		onDeleteSubTag,
		onUpdateSubTag,
		onCountProductsWithSubTag,
		onCountProductsWithTag,
		onSearchParamsTag
	};

	return (
		<Sb1010sContext.Provider value={value}>{children}</Sb1010sContext.Provider>
	);
};
