import React from "react";

interface Props {
	fill?: string;
}

const NotificationSvg: React.FC<Props> = ({ fill = "#025CB7" }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 28 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="notification-svg"
				d="M13.9658 31.9988C14.863 32.0229 15.7334 31.6914 16.3873 31.0767C17.0412 30.462 17.4255 29.6139 17.4567 28.717H10.4748C10.506 29.6139 10.8905 30.462 11.5444 31.0767C12.1983 31.6914 13.0687 32.0229 13.9658 31.9988ZM24.4386 24.2629V13.9489C24.4386 8.9114 21.593 4.69424 16.5832 3.57859V2.46305C16.5731 2.12941 16.4974 1.8008 16.3603 1.49646C16.2232 1.19212 16.0275 0.917864 15.7843 0.689232C15.5411 0.460601 15.2552 0.282068 14.943 0.164013C14.6308 0.0459582 14.2984 -0.00926044 13.9648 0.00126541C13.6312 -0.00926044 13.2987 0.0459582 12.9865 0.164013C12.6743 0.282068 12.3883 0.460601 12.1451 0.689232C11.902 0.917864 11.7062 1.19212 11.5691 1.49646C11.4321 1.8008 11.3563 2.12941 11.3462 2.46305V3.57859C6.35357 4.69424 3.49087 8.8954 3.49087 13.9489V24.2629L0 25.436V26.3737H27.9284V25.436L24.4386 24.2629ZM22.9913 24.4975C20.8827 14.141 22.9912 11.2505 18.1521 5.65838C23.7431 7.52596 22.9913 17.0144 22.9913 24.4975Z"
				fill={fill}
			/>
		</svg>
	);
};

export default NotificationSvg;
